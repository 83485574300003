import React from 'react';
import {createSelector} from 'reselect';
import {resourceStatus} from 'src/shared/resourceStatus/selectors';
import {reportIssueEligible, reportIssueAvailable} from 'src/shared/reportIssue/selectors';
import Interruption from 'src/svgs/status/interruption.svg';
import {yellowPumpkin} from '../style.module.scss';

export const reportIssueSupportlet = createSelector(
  [resourceStatus, reportIssueAvailable],
  ({complete}, reportIssueAvailable) => ({
    ready: complete.account && complete.datacast && complete.hic && complete.outages,
    eligible: reportIssueAvailable,
    color: yellowPumpkin,
    icon: <Interruption />,
    id: 'H&S_REPORT_ISSUE_TOAST',
    links: [{
      url: 'report-issue/',
      text: 'Check for Issues',
      target: '_self',
    }],
    main: 'Your services are up but you may be having equipment issues',
    sub: 'We recommend running some additional checks.',
  })
);

export const reportIssueStandBySupportlet = createSelector(
  [resourceStatus, reportIssueEligible, reportIssueAvailable],
  ({complete}, reportIssueEligible, reportIssueAvailable) => ({
    ready: complete.account && complete.datacast && complete.hic && complete.outages && complete.reportIssueTimestamp,
    eligible: reportIssueEligible && !reportIssueAvailable,
    color: yellowPumpkin,
    icon: <Interruption />,
    id: 'H&S_REPORT_ISSUE_STANDBY_TOAST',
    main: <>We&rsquo;re currently looking into your issue.</>,
    sub: 'You should be recieving a text message shortly with updates on your issue.',
  })
);
