import React, {useEffect, useRef} from 'react';
import {dataTrackingForInstance} from 'src/pages/contactUs/components/contactOptionTileDataTracking';
import {contactOption, icon, textContainer, bodyCopy} from '../shared.module.scss';
import {button, textWrap} from 'src/styles/modules/button.module.scss';
import WifiGearIcon from 'src/svgs/wifi_gear.svg';
import {HeadlineTitle} from '../HeadlineTitle';
import {troubleshootInternetProps} from './propTypes';

export const TroubleshootInternet = ({
  title,
  block,
  link: {url, behavior, text, intent},
  launchXfinityAssistant,
  onMobile,
}) => {
  const elem = useRef(null);
  useEffect(() => {
    elem.current.dispatchEvent(
      new CustomEvent('c-tracking-log-dom', {
        bubbles: true,
        composed: true,
        detail: {
          eventMethod: 'component-Track',
          component: [
            {
              componentInfo: {
                componentID: 'contact-option',
                componentName: 'Tile',
                description: 'Tile',
                instanceID: 'TROUBLESHOOT_INTERNET',
              },
            },
          ],
        },
      })
    );
  }, [elem]);
  return (
    <div
      id="troubleshoot-internet"
      className={contactOption}
      ref={elem}
      data-tracking={dataTrackingForInstance('TROUBLESHOOT_INTERNET')}
    >
      <WifiGearIcon className={icon} />

      <div className={textContainer}>
        <HeadlineTitle title={title} />
        {!onMobile && <div className={bodyCopy}>{block}</div>}
        {!onMobile ?
          <button className={`${button} ${textWrap}`} onClick={() => launchXfinityAssistant(intent)}>{text}</button>
          : <a href={url} behavior={behavior}>{text}</a>
        }
      </div>
    </div>
  );
};

TroubleshootInternet.propTypes = troubleshootInternetProps;
