import {
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import {LOAD_SCHEDULED_CALLBACK_APPOINTMENT_ERROR} from 'src/pages/contactUs/store/actions/scheduledCallbackAppointment';
import {READ_ACCOUNT_ERROR} from 'src/shared/account/actions';
import {account} from 'src/shared/account/reducers';
import {auth} from 'src/shared/auth/reducers';
import {content} from 'src/shared/content/reducers';
import {config} from 'src/shared/config/reducers';
import {cookie} from 'src/shared/cookie/reducers';
import {datacast} from 'src/shared/datacast/reducers';
import {events} from 'src/shared/events/middleware';
import {guidedSearch} from 'src/shared/guidedSearch/reducers';
import {LOAD_HIC_ERROR} from 'src/shared/hic/actions';
import {hic} from 'src/shared/hic/reducers';
import {createLoggerMiddleware} from 'src/shared/logger/middleware';
import {
  outageConsolidated,
  outages,
} from 'src/shared/outages/reducers';
import {params} from 'src/shared/params/reducers';
import {
  personalization,
  personalizationHistory,
  supportlets,
} from 'src/shared/personalization/reducers';
import {reportIssueTimestamp} from 'src/shared/reportIssue/reducers';
import {resourceStatus} from 'src/shared/resourceStatus/reducers';
import {user} from 'src/shared/user/reducers';
import {compose} from 'src/shared/utils/compose';
import {app} from './reducers/app';
import {channelTracking} from 'src/pages/contactUs/store/enhancers/channelTracking';
import {connectivityState} from 'src/shared/connectivityState/reducers';

export const store = createStore(
  combineReducers({
    account,
    app,
    auth,
    config,
    content,
    cookie,
    datacast,
    guidedSearch,
    hic,
    outageConsolidated,
    outages,
    params,
    personalization,
    personalizationHistory,
    reportIssueTimestamp,
    resourceStatus,
    supportlets,
    user,
    connectivityState,
  }),
  compose(
    applyMiddleware(
      thunk,
      createLoggerMiddleware({
        component: 'XHS-PAGE-CONTACT-US',
        page: 'contact-us',
        typesWithValues: [
          LOAD_HIC_ERROR,
          LOAD_SCHEDULED_CALLBACK_APPOINTMENT_ERROR,
          READ_ACCOUNT_ERROR,
        ],
      }),
      channelTracking,
      events
    )
  )
);
