import {createSelector} from 'reselect';
import {createResourceSelector} from 'src/shared/utils/resource';

export const account = createResourceSelector('account');

export const services = createSelector(
  account,
  account => account.services || {}
);

export const hasSingleDevice = createSelector(
  services,
  services => [...services.video.devices, ...services.internet.devices].length === 1
);

export const primaryPhone = createSelector(
  account,
  account => account?.phones?.[0] || account?.phoneNumbers?.[0]?.number || ''
);

export const preferredEmail = createSelector(
  account,
  account => account.preferredEmail || ''
);
