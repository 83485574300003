import {AUTH} from 'src/shared/auth/actions';
import {token} from 'src/shared/auth/selectors';
import {scheduleCallbacks} from 'src/shared/config/urls';
import {LOAD_USER_COMPLETE} from 'src/shared/user/actions';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {requiresAccountNumber} from 'src/shared/utils/resourcePrequisites';

export const LOAD_SCHEDULED_CALLBACK_APPOINTMENT_START = 'LOAD_SCHEDULED_CALLBACK_APPOINTMENT_START';
export const LOAD_SCHEDULED_CALLBACK_APPOINTMENT_SUCCESS = 'LOAD_SCHEDULED_CALLBACK_APPOINTMENT_SUCCESS';
export const LOAD_SCHEDULED_CALLBACK_APPOINTMENT_ERROR = 'LOAD_SCHEDULED_CALLBACK_APPOINTMENT_ERROR';
export const LOAD_SCHEDULED_CALLBACK_APPOINTMENT_COMPLETE = 'LOAD_SCHEDULED_CALLBACK_APPOINTMENT_COMPLETE';

export const loadScheduledCallbackAppointment = () => createFetchResourceAction({
  start: LOAD_SCHEDULED_CALLBACK_APPOINTMENT_START,
  success: LOAD_SCHEDULED_CALLBACK_APPOINTMENT_SUCCESS,
  error: LOAD_SCHEDULED_CALLBACK_APPOINTMENT_ERROR,
  complete: LOAD_SCHEDULED_CALLBACK_APPOINTMENT_COMPLETE,
  waitUntil: [AUTH, LOAD_USER_COMPLETE],
  url: scheduleCallbacks,
  qualify: requiresAccountNumber,
  token,
  postProcess: json => json.interactions[0],
});
