import React from 'react';
import {LoadingDots} from 'src/shared/components/LoadingDots';
import {contactError, legalContact} from './style.module.scss';
import {
  legalContact as legalContactPropTypes,
  legalContactLink,
} from './propTypes';
import {contactArticle} from '../shared.module.scss';
import {AUTHENTICATED, ERROR, LOADING, UNAUTHENTICATED} from '../../constants/legalContactStatus';

export const LegalContactLink = ({
  authenticatedLinkText,
  contactInfoUrl,
  errorLinkText,
  signInUrl,
  status,
  unauthenticatedLinkText,
}) => {
  switch (status) {
    case LOADING:
      return <LoadingDots />;
    case AUTHENTICATED:
      return <a href={contactInfoUrl} rel="noopener">{authenticatedLinkText}</a>;
    case UNAUTHENTICATED:
      return <a href={signInUrl} rel="noopener">{unauthenticatedLinkText}</a>;
    case ERROR:
    default:
      return <span className={contactError}>{errorLinkText}</span>;
  }
};

LegalContactLink.propTypes = legalContactLink;

export const LegalContact = ({
  authenticatedLinkText,
  body,
  contactInfoUrl,
  errorLinkText,
  signInUrl,
  status,
  title,
  unauthenticatedLinkText,
}) => (
  <article className={contactArticle}>
    <h2>{title}</h2>
    <section className={legalContact}>
      <p>{body}</p>
      <p>
        <LegalContactLink
          authenticatedLinkText={authenticatedLinkText}
          contactInfoUrl={contactInfoUrl}
          errorLinkText={errorLinkText}
          signInUrl={signInUrl}
          status={status}
          unauthenticatedLinkText={unauthenticatedLinkText}
        />
      </p>
    </section>
  </article>
);

LegalContact.propTypes = legalContactPropTypes;
