const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const pad = n => `${n}`.length < 2 ? `0${n}` : `${n}`;
export const formatTime = date =>
  `${date.getHours() % 12 || 12}:${pad(date.getMinutes())}${date.getHours() >= 12 ? 'pm' : 'am'}`;

const areSameDate = (date1, date2) =>
  date1.getDate() === date2.getDate() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getFullYear() === date2.getFullYear();

export const isPast = datetime => Date.now() > new Date(datetime);

const getDayName = date => {
  const today = new Date();
  if (areSameDate(today, date)) {
    return 'Today';
  }

  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  if (areSameDate(tomorrow, date)) {
    return 'Tomorrow';
  }

  return days[date.getDay()];
};

export const formatMonthDate = date => {
  if (date) {
    const d = new Date(date);
    return `${months[d.getMonth()]} ${d.getDate()}`;
  }
  return '';
};

export const formatDateForDaySelectText = date =>
  `${getDayName(date)}, ${months[date.getMonth()]} ${date.getDate()}`;

export const formatDateForApi = date =>
  date.toString().match(/(\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[-+]\d{4})/)[0];

export const formatDateForDisplay = date =>
  `${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()} at ${formatTime(date)}`;

export const formatDateForDisplaywithRange = (start, end) =>
  `${days[start.getDay()]}, ${months[start.getMonth()]} ${start.getDate()} between ${formatTime(start)} and ${isNaN(end) ? formatTime(new Date(start.getTime() + 30 * 60 * 1000)) : formatTime(end)}`;

export const formatDateForSSM = date =>
  `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}`;

export const formatLocalDatetime = datetimeInUtc => {
  const options = {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'};
  return new Date(datetimeInUtc).toLocaleDateString('en-US', options).replace(/\b(PM|AM)\b/, m => m.toLowerCase());
};

export const formatMinutesSeconds = time => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes ? `${minutes} min${minutes > 1 ? 's' : '' }` : ''}${minutes && seconds ? ' ' : ''}${seconds ? `${seconds} sec${seconds > 1 ? 's' : '' }` : ''}`;
};
