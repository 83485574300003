import React, {useEffect, useRef} from 'react';
import {dataTrackingForInstance} from 'src/pages/contactUs/components/contactOptionTileDataTracking';
import {contactOption, icon, textContainer, bodyCopy} from '../shared.module.scss';
import {button} from 'src/styles/modules/button.module.scss';
import TextUsIcon from 'src/svgs/Contact_Us_Text_Us_Icon.svg';
import {textUsProps} from './propTypes';
import {HeadlineTitle} from '../HeadlineTitle';

export const TextUs = ({
  block,
  link: {
    behavior,
    text,
    url,
  },
  title,
  onMobile,
}) => {
  const elem = useRef(null);
  useEffect(() => {
    elem.current.dispatchEvent(
      new CustomEvent('c-tracking-log-dom', {
        bubbles: true,
        composed: true,
        detail: {
          eventMethod: 'component-Track',
          component: [{
            componentInfo: {
              componentID: 'contact-option',
              componentName: 'Tile',
              description: 'Tile',
              instanceID: 'TEXT_US',
            },
          }],
        },
      })
    );
  }, [elem]);
  return (
    <div id="text-us" className={contactOption} ref={elem} data-tracking={dataTrackingForInstance('TEXT_US')}>
      <TextUsIcon className={icon} />
      <div className={textContainer}>
        <HeadlineTitle title={title} />
        {!onMobile && <div className={bodyCopy}>{block}</div>}
        <a className={!onMobile && button} href={url} behavior={behavior}>{text}</a>
      </div>
    </div>
  );
};

TextUs.propTypes = textUsProps;
