import React from 'react';
import {business} from './style.module.scss';
import {contactArticle} from '../shared.module.scss';
import {businessSupport} from './propTypes';

export const BusinessSupport = ({
  title,
  body,
  link,
}) => (
  <article className={contactArticle}>
    <h2>{title}</h2>
    <section className={business}>
      <p>{body}</p>
      <p><a href={link.url} behavior={link.behavior}>{link.text}</a></p>
    </section>
  </article>
);

BusinessSupport.propTypes = businessSupport;
