import {createSelector} from 'reselect';
import {services} from 'src/shared/account/selectors';
import {hasReportOutageSegment} from 'src/shared/datacast/selectors';
import {hic} from 'src/shared/hic/selectors';
import {
  GREEN,
  GREY,
  RED,
  YELLOW,
} from 'src/shared/outages/constants';
import {
  futureOutage,
  outages,
  outagesStatusColor,
  outageTimeFrame,
} from 'src/shared/outages/selectors';
import {resourceStatus} from 'src/shared/resourceStatus/selectors';
import {userDisconnected} from 'src/shared/user/selectors';

export const CHECKING = 'CHECKING';
export const DISCONNECTED = 'DISCONNECTED';
export const FUTURE = 'FUTURE';
export const REPORT_ISSUE_COOLDOWN = 15 * 60 * 1000;

export const statusPriority = {
  [RED]: 0,
  [YELLOW]: 1,
  [GREEN]: 2,
  [GREY]: 3,
};

export const servicesLoading = createSelector(
  [resourceStatus],
  ({loaded, error}) => !loaded.account && !error.account
);

export const inAnOutage = createSelector(
  [outages, outageTimeFrame, resourceStatus],
  (outages, outageTimeFrame, resourceStatus) => {
    return resourceStatus.loaded.outages
      && outageTimeFrame
      && outageTimeFrame !== 'FUTUREOUTAGE';
  }
);

export const serviceProperties = (type, x1, outagesStatusColor, userDisconnected) => {
  switch (type) {
    case 'internet':
      return {
        type,
        x1,
        name: 'Internet',
        statusColor: userDisconnected ? GREY : outagesStatusColor['internetOutage'],
        troubleshootingLink: 'repair/internet/?continue=status/',
      };
    case 'voice':
      return {
        type,
        x1,
        name: 'Voice',
        statusColor: userDisconnected ? GREY : outagesStatusColor['voiceOutage'],
        troubleshootingLink: 'repair/internet/?continue=status/',
      };
    case 'video':
      return {
        type,
        x1,
        name: `${x1 ? 'X1 ' : ''}TV`,
        statusColor: userDisconnected ? GREY : outagesStatusColor['tvOutage'],
        troubleshootingLink: 'repair/tv/?continue=status/',
      };
    case 'internetvoice':
      return {
        type,
        x1,
        name: 'Internet and Voice',
        statusColor: (() => {
          if (userDisconnected) {
            return GREY;
          }
          const internet = outagesStatusColor['internetOutage'];
          const voice = outagesStatusColor['voiceOutage'];
          if (internet === RED || voice === RED) {
            return RED;
          }
          if (internet === YELLOW || voice === YELLOW) {
            return YELLOW;
          }
          if (internet === GREEN && voice === GREEN) {
            return GREEN;
          }
          if (internet === GREY || voice === GREY) {
            return GREY;
          }
          return CHECKING;
        })(),
        troubleshootingLink: 'repair/internet/?continue=status/',
      };
    default:
  }
};

export const deviceProperties = ({status = {}}, userDisconnected) => {
  const statusUiMapping = {
    [GREEN]: {
      status: 'good',
      statusColor: GREEN,
      statusText: 'Connected',
    },
    [YELLOW]: {
      status: 'warning',
      statusColor: YELLOW,
      statusText: 'Status Unavailable',
    },
    [RED]: {
      status: 'error',
      statusColor: RED,
      statusText: 'Not Connected',
    },
    [GREY]: {
      status: 'unknown',
      statusColor: GREY,
      statusText: 'Status Unavailable',
    },
    [CHECKING]: {
      status: 'checking',
      statusColor: CHECKING,
      statusText: 'Checking',
    },
  };

  let {color = GREY} = status;
  if (
    !statusUiMapping[color]
    || userDisconnected
  ) {
    color = GREY;
  }
  return statusUiMapping[color];
};

export const devicesAllOut = createSelector(
  [services, hic, outagesStatusColor],
  (services, hic, outagesStatusColor) => {
    const formattedServices = Object
      .entries(services)
      .filter(([type, service]) => service.subscribed && ['internet', 'video', 'voice'].includes(type))
      .map(([type, service]) => {
        const x1 = (service?.devices || []).some(device => device.x1);
        return {
          ...service,
          ...serviceProperties(type, x1, outagesStatusColor),
          devices: (service?.devices || []).map(device => {
            const hicDevice = (hic?.services?.[type]?.devices || [])
              .find(d => d.deviceDetails?.serialNumber === device.serialNumber) || {};
            const color = hicDevice?.statusColor;
            return {
              ...device,
              ...deviceProperties({status: {color}}),
            };
          }),
        };
      }).reduce((acc, service) => acc.concat(service.devices), []);
    return formattedServices.length > 0 && formattedServices.every(device => device.statusColor === RED);
  }
);

export const reportIssueTimestamp = state => state.reportIssueTimestamp || 0;

export const reportIssueEligible = createSelector(
  [hasReportOutageSegment, devicesAllOut, inAnOutage],
  (hasSegment, devicesAllOut, inAnOutage) => {
    return hasSegment
      && devicesAllOut
      && !inAnOutage;
  }
);

export const reportIssueCooldownComplete = createSelector(
  [() => Date.now(), reportIssueTimestamp],
  (now, reportIssueTimestamp) => reportIssueTimestamp + REPORT_ISSUE_COOLDOWN < now
);

export const reportIssueAvailable = createSelector(
  [reportIssueEligible, reportIssueCooldownComplete],
  (reportIssueEligible, reportIssueCooldownComplete) => reportIssueEligible && reportIssueCooldownComplete
);

export const serviceCode = createSelector(
  [outagesStatusColor, futureOutage, userDisconnected, resourceStatus],
  (outagesStatusColor, futureOutage, userDisconnected, {loaded, error}) => {
    if (userDisconnected) {
      return DISCONNECTED;
    }
    if (futureOutage) {
      return FUTURE;
    }
    if (!loaded.account || !loaded.outages) {
      if (error.account || error.outages) {
        return GREY;
      }
      return CHECKING;
    }
    return Object.values(outagesStatusColor).sort((a, b) => statusPriority[a] - statusPriority[b])[0];
  }
);
