export const dataTrackingForInstance = instanceID => JSON.stringify({
  containerName: 'Contact Options',
  component: [
    {
      componentInfo: {
        componentID: 'contact-option',
        componentName: 'Tile',
        description: 'Tile',
        instanceID,
      },
    },
  ],
});

