import {AUTH} from 'src/shared/auth/actions';
import {token} from 'src/shared/auth/selectors';
import {contactUs} from 'src/shared/config/urls';
import {createFetchResourceAction} from 'src/shared/utils/resource';

export const LOAD_CONTACT_INFO_URL_START = 'LOAD_CONTACT_INFO_URL_START';
export const LOAD_CONTACT_INFO_URL_SUCCESS = 'LOAD_CONTACT_INFO_URL_SUCCESS';
export const LOAD_CONTACT_INFO_URL_ERROR = 'LOAD_CONTACT_INFO_URL_ERROR';
export const LOAD_CONTACT_INFO_URL_COMPLETE = 'LOAD_CONTACT_INFO_URL_COMPLETE';

export const loadContactInfoUrl = () => createFetchResourceAction({
  start: LOAD_CONTACT_INFO_URL_START,
  success: LOAD_CONTACT_INFO_URL_SUCCESS,
  error: LOAD_CONTACT_INFO_URL_ERROR,
  complete: LOAD_CONTACT_INFO_URL_COMPLETE,
  waitUntil: AUTH,
  url: contactUs,
  token,
});
