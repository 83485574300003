import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
} from 'react';
import FacebookMessenger from 'src/svgs/social/facebookmessenger.svg';
import SocialForums from 'src/svgs/social/forums.svg';
import Reddit from 'src/svgs/social/reddit.svg';
import XIcon from 'src/svgs/social/x.svg';
import {helpCommunities} from './propTypes';
import {
  social,
  socialLinks,
  socialLinkText,
} from './style.module.scss';

export const CommunityIcon = ({iconId}) => {
  switch (iconId) {
    case 'social:forums':
      return (<SocialForums />);
    case 'social:facebookmessenger':
      return (<FacebookMessenger />);
    case 'social:reddit':
      return (<Reddit />);
    case 'social:twitter':
      return (<XIcon />);
    default:
      return null;
  }
};

CommunityIcon.propTypes = {
  iconId: PropTypes.string.isRequired,
};

const dataTracking = (item, numItems) => JSON.stringify({
  containerName: 'Contact Us Social Links Option',
  layout: `${numItems}up`,
  component: [
    {
      componentInfo: {
        componentID: `social-option-${numItems}`,
        componentName: 'Tile',
        description: 'Tile',
        instanceID: item.title,
      },
    },
  ],
});

export const HelpCommunities = ({
  communities,
  title,
}) => {
  const elem = useRef(null);
  useEffect(() => {
    const componentID = `social-option-${communities.length}`;
    Array.prototype.forEach.call(elem.current.children, (child, index) => {
      child.dispatchEvent(
        new CustomEvent('c-tracking-log-dom', {
          bubbles: true,
          composed: true,
          detail: {
            eventMethod: 'component-Track',
            component: [{
              componentInfo: {
                componentID,
                componentName: 'Tile',
                description: 'Tile',
                instanceID: communities[index].title,
              },
            }],
          },
        })
      );
    });
  }, [elem]);

  return (
    <>
      <h2>{title}</h2>
      <div className={socialLinks} ref={elem}>
        {
          communities.map(community => (
            <div className={social} key={community.title} data-tracking-parent data-tracking={dataTracking(community, communities.length)}>
              <CommunityIcon iconId={community.iconId} />
              <div className={socialLinkText}>
                <span>{community.title}</span>
                <a href={community.link.url} behavior={community.link.behavior} rel="noopener noreferrer">{community.link.text}</a>
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
};

HelpCommunities.propTypes = helpCommunities;
