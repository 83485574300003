import {loadContactInfoUrl} from 'src/pages/contactUs/store/actions/contactInfoUrl';
import {loadScheduledCallbackAppointment} from 'src/pages/contactUs/store/actions/scheduledCallbackAppointment';
import {readAccount} from 'src/shared/account/actions';
import {
  AUTH,
  authenticate,
} from 'src/shared/auth/actions';
import {isAuthenticated} from 'src/shared/auth/selectors';
import {triggerChannelTracking} from 'src/shared/channelTracking/actions/triggerChannelTrackingAction';
import {flags} from 'src/shared/config/selectors';
import {
  datacast,
  READ_DATACAST_COMPLETE,
} from 'src/shared/datacast/actions';
import {
  hasOutageMessagesSegment,
  hasProactiveIssueDetectionSegment,
} from 'src/shared/datacast/selectors';
import {startDataLayerTracking} from 'src/shared/dataLayer/actions';
import {waitUntil} from 'src/shared/events';
import {loadHic} from 'src/shared/hic/actions';
import {preloadItgRecommendations} from 'src/shared/itgRecommendations/actions';
import {
  loadOutageConsolidated,
  loadOutages,
} from 'src/shared/outages/actions';
import {
  loadPersonalization,
  loadPersonalizationHistory,
} from 'src/shared/personalization/actions';
import {readReportIssueTimestamp} from 'src/shared/reportIssue/actions';
import {loadUser} from 'src/shared/user/actions';
import {
  LOAD_CONNECTIVITYSTATE_COMPLETE,
  loadConnectivityState,
} from 'src/shared/connectivityState/actions';

export const startPage = () => async (dispatch, getState) => {
  dispatch(authenticate());
  dispatch(loadUser());
  dispatch(startDataLayerTracking());
  dispatch(loadConnectivityState());

  await waitUntil(AUTH);
  if (isAuthenticated(getState())) {
    dispatch(datacast());
    dispatch(readAccount());
    dispatch(loadHic());
    dispatch(readReportIssueTimestamp());
    dispatch(loadContactInfoUrl());
    if (flags(getState()).virtualHoldLinksEnabled) {
      dispatch(loadScheduledCallbackAppointment());
    }
    dispatch(loadPersonalization());
    dispatch(loadPersonalizationHistory());
    await waitUntil(READ_DATACAST_COMPLETE);
    dispatch(triggerChannelTracking());
    await waitUntil(LOAD_CONNECTIVITYSTATE_COMPLETE);
    if (hasProactiveIssueDetectionSegment(getState())) {
      dispatch(preloadItgRecommendations());
    }
    if (hasOutageMessagesSegment(getState())) {
      dispatch(loadOutageConsolidated());
    } else {
      dispatch(loadOutages());
    }
  }
};
