import {connect} from 'react-redux';
import {PageContactUs} from 'src/pages/contactUs/components/PageContactUs';
import {launchXfinityAssistant} from 'src/shared/xfinityAssistant/actions';
import {
  businessSupportContent,
  chatContent,
  findLocationContent,
  helpCommunitiesContent,
  legalContactContent,
  messageFromTomKContent,
  statusCenterContent,
  support24HrsContent,
  talkToAgentContent,
  xfinityAppContent,
  manageBillingContent,
  textUsContent,
  accessEmailContent,
  troubleshootInternetContent,
} from 'src/pages/contactUs/store/selectors';
import 'src/styles/base.scss';
import {flags} from 'src/shared/config/selectors';
import {
  futureOutage,
  outageImpact,
  outageList, pastOutage,
} from 'src/shared/outages/selectors';
import {outageNotice} from 'src/shared/outages/selectors/outageNotice';
import {hasOutageMessagesSegment} from 'src/shared/datacast/selectors';
import {isStormReadyWifi} from 'src/shared/connectivityState/selectors';

const mapStateToProps = state => ({
  appTileEnabled: flags(state).appTileEnabled,
  businessSupportContent: businessSupportContent(state),
  chatContent: chatContent(state),
  findLocationContent: findLocationContent(state),
  futureOutage: futureOutage(state),
  hasOutageMessagesSegment: hasOutageMessagesSegment(state),
  helpCommunitiesContent: helpCommunitiesContent(state),
  legalContactContent: legalContactContent(state),
  messageFromTomKContent: messageFromTomKContent(state),
  outageImpact: outageImpact(state),
  outageNotice: outageNotice(state),
  statusCenterContent: statusCenterContent(state),
  support24HrsContent: support24HrsContent(state),
  supportTileEnabled: flags(state).supportTileEnabled,
  talkToAgentContent: talkToAgentContent(state),
  virtualHoldLinksEnabled: flags(state).virtualHoldLinksEnabled,
  xfinityAppContent: xfinityAppContent(state),
  isStormReadyWifi: isStormReadyWifi(state),
  manageBillingContent: manageBillingContent(state),
  troubleshootInternetContent: troubleshootInternetContent(state),
  accessEmailContent: accessEmailContent(state),
  textUsContent: textUsContent(state),
  outageList: outageList(state),
  pastOutage: pastOutage(state),
});

const mapDispatchToProps = dispatch => ({
  launchXfinityAssistant: intent => dispatch(launchXfinityAssistant(undefined /* no utterance */, intent)),
});

export const App = connect(mapStateToProps, mapDispatchToProps)(PageContactUs);
