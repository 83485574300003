import React from 'react';
import {messageFrom, messageBody, messageImage} from './style.module.scss';
import {messageFromTomK} from './propTypes';

export const MessageFromTomK = ({
  body,
  display,
  image,
  link,
  title,
}) => {
  if (display) {
    return (
      <article>
        <h2>{title}</h2>
        <section className={messageFrom}>
          <div className={messageImage} style={{backgroundImage: `url(${image.url})`}} />
          <div className={messageBody}>
            <p>{body}</p>
            {
              link.display &&
              <a href={link.url} behavior={link.behavior}>{link.text}</a>
            }
          </div>
        </section>
      </article>
    );
  }
  return null;
};

MessageFromTomK.propTypes = messageFromTomK;
