import React from 'react';
import PropTypes from 'prop-types';
import {headline} from './style.module.scss';

export const HeadlineTitle = ({title}) => {
  return <div className={headline}>{title}</div>;
};

HeadlineTitle.propTypes = {
  title: PropTypes.string,
};
