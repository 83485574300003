import {createSelector} from 'reselect';
import {CONTACT_US} from 'src/pages/contactUs/constants';
import {
  IMMEDIATE_CALLBACK,
  SCHEDULED_CALLBACK,
} from 'src/pages/contactUs/constants/appointmentType';
import {BILLING} from 'src/shared/channelTracking/constants/intent';
import {BILLING_PAYMENTS} from 'src/shared/channelTracking/constants/interactionType';
import {
  CALLBACK_LOADING,
  CALLBACK_NOT_SCHEDULED,
  CALLBACK_SCHEDULED,
} from 'src/pages/contactUs/constants/callbackStatus';
import {isAuthenticated} from 'src/shared/auth/selectors';
import {content} from 'src/shared/content/selectors';
import {cookie} from 'src/shared/cookie/selectors';
import {signIn} from 'src/shared/config/urls';
import {resourceStatus} from 'src/shared/resourceStatus/selectors';
import {currentLanguage} from 'src/shared/utils/currentLanguage';
import {
  AUTHENTICATED,
  ERROR,
  LOADING,
  UNAUTHENTICATED,
} from '../constants/legalContactStatus';

export const app = state => state.app;

export const appointment = createSelector(
  app,
  app => app.appointment
);

export const callbackStatus = createSelector(
  [isAuthenticated, resourceStatus, appointment],
  (isAuthenticated, {complete}, appointment) => {
    if (!isAuthenticated) {
      return CALLBACK_NOT_SCHEDULED;
    }
    if (!complete.scheduledCallbackAppointment) {
      return CALLBACK_LOADING;
    }
    if (appointment.type === SCHEDULED_CALLBACK || appointment.type === IMMEDIATE_CALLBACK) {
      return CALLBACK_SCHEDULED;
    }
    return CALLBACK_NOT_SCHEDULED;
  }
);

export const contactInfoUrl = createSelector(
  app,
  app => app.contactInfoUrl
);

export const contactInfoError = createSelector(
  app,
  app => app.contactInfoError
);

export const findLocationContent = createSelector(
  content,
  content => content?.contactUs?.contactTiles?.content?.[2] || {}
);

export const statusCenterContent = createSelector(
  content,
  content => content?.contactUs?.contactTiles?.content?.[3] || {}
);
export const support24HrsContent = createSelector(
  content,
  content => content?.contactUs?.contactTiles?.content?.[7] || {}
);
export const xfinityAppContent = createSelector(
  content,
  content => content?.contactUs?.contactTiles?.content?.[8] || {}
);
export const textUsContent = createSelector(
  content,
  content => content?.contactUs?.contactTiles?.content?.find(x => x.id === 'textUs') || {}
);

export const manageBillingContent = createSelector(
  content,
  content => content?.contactUs?.contactTiles?.content?.find(x => x.id === 'manageBilling') || {}
);

export const troubleshootInternetContent = createSelector(
  content,
  content => content?.contactUs?.contactTiles?.content?.find(x => x.id === 'troubleshootInternet') || {}
);

export const accessEmailContent = createSelector(
  content,
  content => content?.contactUs?.contactTiles?.content?.find(x => x.id === 'accessEmail') || {}
);

export const talkToAgentContent = createSelector(
  [content, callbackStatus, appointment],
  (content, callbackStatus, appointment) => ({
    appointment,
    callbackStatus,
    callbackScheduledContent: content?.contactUs?.contactTiles?.content?.[4] || {},
    talkToAgentContent: content?.contactUs?.contactTiles?.content?.[0] || {},
  })
);

export const chatContent = createSelector(
  [content, currentLanguage],
  (content, currentLanguage) => ({
    currentLanguage,
    chatWithTechContent: content?.contactUs?.contactTiles?.content?.[1] || {},
    chatWithXfinityAssistantContent: content?.contactUs?.contactTiles?.content?.[6] || {},
    esChatWithTechContent: content?.contactUs?.contactTiles?.content?.[5] || {},
  })
);

export const helpCommunitiesContent = createSelector(
  [content, cookie],
  (content, cookie) => {
    const communities = content?.contactUs?.communities?.content?.[0]?.links.filter(({segment}) => !!cookie[segment]);
    const title = !!communities.length ? content?.contactUs?.communities?.title : '';
    return {communities, title};
  }
);

const legalContactStatus = createSelector(
  [isAuthenticated, contactInfoUrl, contactInfoError],
  (isAuthenticated, contactInfoUrl, contactInfoError) => {
    if (contactInfoError) {
      return ERROR;
    }
    if (!isAuthenticated) {
      return UNAUTHENTICATED;
    }
    return contactInfoUrl ? AUTHENTICATED : LOADING;
  }
);

const legalContentUnauthenticated = createSelector(
  content,
  content => content?.contactUs?.contactLegal?.content?.[1]
);

const legalContentAuthenticated = createSelector(
  content,
  content => content?.contactUs?.contactLegal?.content?.[2]
);

const legalContentError = createSelector(
  content,
  content => content?.contactUs?.contactLegal?.content?.[0]
);

export const legalContactContent = createSelector(
  [
    contactInfoUrl,
    signIn,
    legalContactStatus,
    legalContentAuthenticated,
    legalContentUnauthenticated,
    legalContentError,
  ],
  (
    contactInfoUrl,
    signInUrl,
    status,
    authenticated,
    unauthenticated,
    error
  ) => ({
    authenticatedLinkText: authenticated?.link?.text,
    body: (() => {
      switch (status) {
        case ERROR:
          return error.block;
        case AUTHENTICATED:
          return authenticated.block;
        case UNAUTHENTICATED:
        case LOADING:
        default:
          return unauthenticated.block;
      }
    })(),
    contactInfoUrl,
    errorLinkText: error?.link?.text,
    signInUrl,
    status,
    title: (() => {
      switch (status) {
        case ERROR:
          return error.title;
        case AUTHENTICATED:
          return authenticated.title;
        case UNAUTHENTICATED:
        case LOADING:
        default:
          return unauthenticated.title;
      }
    })(),
    unauthenticatedLinkText: unauthenticated?.link?.text,
  })
);

const businessContent = createSelector(
  content,
  content => content?.contactUs?.contactBusiness?.content?.[0]
);

export const businessSupportContent = createSelector(
  [businessContent],
  content => ({
    body: content.block,
    link: content.link,
    title: content.title,
  })
);

const tomKContent = createSelector(
  content,
  content => content?.contactUs?.contactTomK?.content?.[0] || {}
);

export const messageFromTomKContent = createSelector(
  [tomKContent],
  content => ({
    body: content.text,
    display: content.displayTitleText === 'show',
    image: {
      url: content.imageUrl,
    },
    link: {
      behavior: content?.link?.behavior,
      display: content.displayImageLink === 'show',
      text: content?.link?.text,
      url: content?.link?.url,
    },
    title: content.title,
  })
);

export const contactUsChannelTracking = {
  action: {
    id: CONTACT_US,
    intent: BILLING,
    metadata: {
      targetChannel: 'H&S',
    },
  },
  eventTimestamp: Date.now().toString(),
  interactionType: BILLING_PAYMENTS,
};

