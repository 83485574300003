import {combineReducers} from 'redux';
import {
  LOAD_CONTACT_INFO_URL_ERROR,
  LOAD_CONTACT_INFO_URL_SUCCESS,
} from '../actions/contactInfoUrl';
import {LOAD_SCHEDULED_CALLBACK_APPOINTMENT_SUCCESS} from '../actions/scheduledCallbackAppointment';

import {formatDateForDisplay} from 'src/shared/utils/date';

export const appointment = (state = {}, action) => {
  switch (action.type) {
    case LOAD_SCHEDULED_CALLBACK_APPOINTMENT_SUCCESS:
      const appointment = action?.value?.resource;
      if (appointment) {
        if (appointment.appointmentTime) {
          const date = new Date(appointment.appointmentTime);
          return Object.assign({}, appointment, {time: formatDateForDisplay(date)});
        }
        return appointment;
      }
      return {};
    default:
      return state;
  }
};

export const contactInfoUrl = (state = null, action) => {
  switch (action.type) {
    case LOAD_CONTACT_INFO_URL_SUCCESS:
      return action.value.resource.contactPdf;
    default:
      return state;
  }
};

export const contactInfoError = (state = false, action) => {
  switch (action.type) {
    case LOAD_CONTACT_INFO_URL_ERROR:
      return true;
    case LOAD_CONTACT_INFO_URL_SUCCESS:
      return !action.value.resource.contactPdf;
    default:
      return state;
  }
};

export const app = combineReducers({
  appointment,
  contactInfoUrl,
  contactInfoError,
});
