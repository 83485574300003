import {createResourceSelector} from 'src/shared/utils/resource';
import {createSelector} from 'reselect';

export const hic = createResourceSelector('hic');

export const services = createSelector(
  hic,
  hic => hic.services || {}
);

export const videoDevices = createSelector(
  services,
  services => services?.video?.devices || []
);
