import {waitUntil} from 'src/shared/events';
import {LOAD_USER_COMPLETE} from 'src/shared/user/actions';
import {accountNumber} from 'src/shared/user/selectors';
import {errorToJSON} from 'src/shared/utils/errorToJSON';
import {
  readJSONFromLocalStorage,
  writeJSONToLocalStorage,
} from 'src/shared/utils/storage';

const REPORT_ISSUE_TIMESTAMP_STORAGE_KEY = 'issue-reported';
const REPORT_ISSUE_ELIGIBLE_STORAGE_KEY = 'report-issue-eligible';

export const READ_REPORT_ISSUE_TIMESTAMP_START = 'READ_REPORT_ISSUE_TIMESTAMP_START';
export const READ_REPORT_ISSUE_TIMESTAMP_SUCCESS = 'READ_REPORT_ISSUE_TIMESTAMP_SUCCESS';
export const READ_REPORT_ISSUE_TIMESTAMP_ERROR = 'READ_REPORT_ISSUE_TIMESTAMP_ERROR';
export const READ_REPORT_ISSUE_TIMESTAMP_COMPLETE = 'READ_REPORT_ISSUE_TIMESTAMP_COMPLETE';

export const readReportIssueTimestampStart = () => ({
  type: READ_REPORT_ISSUE_TIMESTAMP_START,
});

export const readReportIssueTimestampSuccess = timestamp => ({
  type: READ_REPORT_ISSUE_TIMESTAMP_SUCCESS,
  value: {timestamp},
});

export const readReportIssueTimestampError = error => ({
  type: READ_REPORT_ISSUE_TIMESTAMP_ERROR,
  value: {error: errorToJSON(error)},
});

export const readReportIssueTimestampComplete = () => ({
  type: READ_REPORT_ISSUE_TIMESTAMP_COMPLETE,
});

export const readReportIssueTimestamp = () => async (dispatch, getState) => {
  dispatch(readReportIssueTimestampStart());
  await waitUntil(LOAD_USER_COMPLETE);

  try {
    if (!accountNumber(getState())) {
      return dispatch(readReportIssueTimestampError(new Error('Missing account number.')));
    }
    const reportIssueHistory = readJSONFromLocalStorage(REPORT_ISSUE_TIMESTAMP_STORAGE_KEY) || {};
    const lastIssueReport = reportIssueHistory[accountNumber(getState())] || 0;
    dispatch(readReportIssueTimestampSuccess(lastIssueReport));
  } catch (error) {
    dispatch(readReportIssueTimestampError(error));
  } finally {
    dispatch(readReportIssueTimestampComplete());
  }
};

export const CREATE_REPORT_ISSUE_TIMESTAMP_START = 'CREATE_REPORT_ISSUE_TIMESTAMP_START';
export const CREATE_REPORT_ISSUE_TIMESTAMP_SUCCESS = 'CREATE_REPORT_ISSUE_TIMESTAMP_SUCCESS';
export const CREATE_REPORT_ISSUE_TIMESTAMP_ERROR = 'CREATE_REPORT_ISSUE_TIMESTAMP_ERROR';
export const CREATE_REPORT_ISSUE_TIMESTAMP_COMPLETE = 'CREATE_REPORT_ISSUE_TIMESTAMP_COMPLETE';

export const createReportIssueTimestampStart = () => ({
  type: CREATE_REPORT_ISSUE_TIMESTAMP_START,
});

export const createReportIssueTimestampSuccess = timestamp => ({
  type: CREATE_REPORT_ISSUE_TIMESTAMP_SUCCESS,
  value: {timestamp},
});

export const createReportIssueTimestampError = error => ({
  type: CREATE_REPORT_ISSUE_TIMESTAMP_ERROR,
  value: {error: errorToJSON(error)},
});

export const createReportIssueTimestampComplete = () => ({
  type: CREATE_REPORT_ISSUE_TIMESTAMP_COMPLETE,
});

export const createReportIssueTimestamp = (timestamp = Date.now()) => async (dispatch, getState) => {
  dispatch(createReportIssueTimestampStart());
  await waitUntil(LOAD_USER_COMPLETE);
  const {user: {accountNumber}} = getState();
  try {
    const reportIssueHistory = readJSONFromLocalStorage(REPORT_ISSUE_TIMESTAMP_STORAGE_KEY) || {};
    reportIssueHistory[accountNumber] = timestamp;
    writeJSONToLocalStorage(REPORT_ISSUE_TIMESTAMP_STORAGE_KEY, reportIssueHistory);
    dispatch(createReportIssueTimestampSuccess(timestamp));
  } catch (error) {
    dispatch(createReportIssueTimestampError(error));
  } finally {
    dispatch(createReportIssueTimestampComplete());
  }
};

export const READ_REPORT_ISSUE_ELIGIBILITY_START = 'READ_REPORT_ISSUE_ELIGIBILITY_START';
export const READ_REPORT_ISSUE_ELIGIBILITY_SUCCESS = 'READ_REPORT_ISSUE_ELIGIBILITY_SUCCESS';
export const READ_REPORT_ISSUE_ELIGIBILITY_ERROR = 'READ_REPORT_ISSUE_ELIGIBILITY_ERROR';
export const READ_REPORT_ISSUE_ELIGIBILITY_COMPLETE = 'READ_REPORT_ISSUE_ELIGIBILITY_COMPLETE';

export const readReportIssueEligiblityStart = () => ({
  type: READ_REPORT_ISSUE_ELIGIBILITY_START,
});

export const readReportIssueEligibilitySuccess = eligible => ({
  type: READ_REPORT_ISSUE_ELIGIBILITY_SUCCESS,
  value: {eligible},
});

export const readReportIssueEligibilityError = error => ({
  type: READ_REPORT_ISSUE_ELIGIBILITY_ERROR,
  value: {error: errorToJSON(error)},
});

export const readReportIssueEligibilityComplete = () => ({
  type: READ_REPORT_ISSUE_ELIGIBILITY_COMPLETE,
});

const ELIGIBILITY_WINDOW = 15 * 60 * 1000;

export const readReportIssueEligibility = (now = Date.now()) => async (dispatch, getState) => {
  dispatch(readReportIssueEligiblityStart());
  await waitUntil(LOAD_USER_COMPLETE);
  const {accountNumber} = getState().user;

  if (accountNumber) {
    try {
      const reportIssueEligible = readJSONFromLocalStorage(REPORT_ISSUE_ELIGIBLE_STORAGE_KEY) || {};
      const {eligible = false, timestamp = 0} = reportIssueEligible[accountNumber] || {};
      dispatch(readReportIssueEligibilitySuccess(eligible && timestamp + ELIGIBILITY_WINDOW > now));
    } catch (error) {
      dispatch(readReportIssueEligibilityError(error));
    }
  } else {
    dispatch(readReportIssueEligibilityError(new Error('No account number found.')));
  }
  dispatch(readReportIssueEligibilityComplete());
};

export const CREATE_REPORT_ISSUE_ELIGIBILITY_START = 'CREATE_REPORT_ISSUE_ELIGIBILITY_START';
export const CREATE_REPORT_ISSUE_ELIGIBILITY_SUCCESS = 'CREATE_REPORT_ISSUE_ELIGIBILITY_SUCCESS';
export const CREATE_REPORT_ISSUE_ELIGIBILITY_ERROR = 'CREATE_REPORT_ISSUE_ELIGIBILITY_ERROR';
export const CREATE_REPORT_ISSUE_ELIGIBILITY_COMPLETE = 'CREATE_REPORT_ISSUE_ELIGIBILITY_COMPLETE';

export const createReportIssueEligibilityStart = () => ({
  type: CREATE_REPORT_ISSUE_ELIGIBILITY_START,
});

export const createReportIssueEligibilitySuccess = eligible => ({
  type: CREATE_REPORT_ISSUE_ELIGIBILITY_SUCCESS,
  value: {eligible},
});

export const createReportIssueEligibilityError = error => ({
  type: CREATE_REPORT_ISSUE_ELIGIBILITY_ERROR,
  value: {error: errorToJSON(error)},
});

export const createReportIssueEligibilityComplete = () => ({
  type: CREATE_REPORT_ISSUE_ELIGIBILITY_COMPLETE,
});

export const createReportIssueEligibility = (eligible, timestamp = Date.now()) => async (dispatch, getState) => {
  dispatch(createReportIssueEligibilityStart());
  await waitUntil(LOAD_USER_COMPLETE);
  const {accountNumber} = getState().user;

  if (accountNumber) {
    try {
      const reportIssueEligible = readJSONFromLocalStorage(REPORT_ISSUE_ELIGIBLE_STORAGE_KEY) || {};
      reportIssueEligible[accountNumber] = {eligible, timestamp};
      writeJSONToLocalStorage(REPORT_ISSUE_ELIGIBLE_STORAGE_KEY, reportIssueEligible);
      dispatch(createReportIssueEligibilitySuccess(eligible));
    } catch (error) {
      dispatch(createReportIssueEligibilityError(error));
    }
  } else {
    dispatch(createReportIssueEligibilityError(new Error('Missing account number.')));
  }
  dispatch(createReportIssueEligibilityComplete());
};
