import {AUTH} from 'src/shared/auth/actions';
import {token} from 'src/shared/auth/selectors';
import {hic} from 'src/shared/config/urls';
import {LOAD_USER_COMPLETE} from 'src/shared/user/actions';
import {createFetchResourceAction} from 'src/shared/utils/resource';

export const LOAD_HIC_START = 'LOAD_HIC_START';
export const LOAD_HIC_SUCCESS = 'LOAD_HIC_SUCCESS';
export const LOAD_HIC_ERROR = 'LOAD_HIC_ERROR';
export const LOAD_HIC_COMPLETE = 'LOAD_HIC_COMPLETE';

export const loadHic = () => createFetchResourceAction({
  start: LOAD_HIC_START,
  success: LOAD_HIC_SUCCESS,
  error: LOAD_HIC_ERROR,
  complete: LOAD_HIC_COMPLETE,
  waitUntil: [AUTH, LOAD_USER_COMPLETE],
  url: hic,
  token,
});
