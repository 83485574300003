import React, {useEffect, useRef} from 'react';
import {dataTrackingForInstance} from 'src/pages/contactUs/components/contactOptionTileDataTracking';
import {contactOption, icon, textContainer, bodyCopy} from '../shared.module.scss';
import {button} from 'src/styles/modules/button.module.scss';
import EmailIcon from 'src/svgs/email.svg';
import {HeadlineTitle} from '../HeadlineTitle';
import {accessEmailProps} from './propTypes';

export const AccessEmail = ({
  title,
  block,
  link: {url, behavior, text},
  onMobile,
}) => {
  const elem = useRef(null);
  useEffect(() => {
    elem.current.dispatchEvent(
      new CustomEvent('c-tracking-log-dom', {
        bubbles: true,
        composed: true,
        detail: {
          eventMethod: 'component-Track',
          component: [
            {
              componentInfo: {
                componentID: 'contact-option',
                componentName: 'Tile',
                description: 'Tile',
                instanceID: 'ACCESS_EMAIL',
              },
            },
          ],
        },
      })
    );
  }, [elem]);
  return (
    <div
      id="access-email"
      className={contactOption}
      ref={elem}
      data-tracking={dataTrackingForInstance('ACCESS_EMAIL')}
    >
      <EmailIcon className={icon} />
      <div className={textContainer}>
        <HeadlineTitle title={title} />
        {!onMobile && <div className={bodyCopy}>{block}</div>}
        <a className={!onMobile && button} href={url} behavior={behavior}>{text}</a>
      </div>
    </div>
  );
};

AccessEmail.propTypes = accessEmailProps;
