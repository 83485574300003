import {
  CREATE_REPORT_ISSUE_ELIGIBILITY_SUCCESS,
  CREATE_REPORT_ISSUE_TIMESTAMP_SUCCESS,
  READ_REPORT_ISSUE_ELIGIBILITY_ERROR,
  READ_REPORT_ISSUE_ELIGIBILITY_SUCCESS,
  READ_REPORT_ISSUE_TIMESTAMP_ERROR,
  READ_REPORT_ISSUE_TIMESTAMP_SUCCESS,
} from './actions';

export const reportIssueTimestamp = (state = 0, action) => {
  switch (action.type) {
    case READ_REPORT_ISSUE_TIMESTAMP_SUCCESS:
    case CREATE_REPORT_ISSUE_TIMESTAMP_SUCCESS:
      return action.value.timestamp;
    case READ_REPORT_ISSUE_TIMESTAMP_ERROR:
      return 0;
    default:
      return state;
  }
};

export const reportIssueEligibility = (state = false, action) => {
  switch (action.type) {
    case READ_REPORT_ISSUE_ELIGIBILITY_SUCCESS:
    case CREATE_REPORT_ISSUE_ELIGIBILITY_SUCCESS:
      return action.value.eligible;
    case READ_REPORT_ISSUE_ELIGIBILITY_ERROR:
      return false;
    default:
      return state;
  }
};
