export const isEmptyOrNull = value => {
  // check for null, undefined and empty string
  if (value === null || value === undefined || value.toString().trim() === '') {
    return true;
  }
  // check for integer
  if (typeof value === 'number') {
    return false;
  }
  // check for empty array
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  // check for empty object
  return !(Object.keys(value).some(key => value[key]));
};
