import React, {useEffect, useRef, useState} from 'react';
import {dataTrackingForInstance} from 'src/pages/contactUs/components/contactOptionTileDataTracking';
import {button} from 'src/styles/modules/button.module.scss';
import {LoadingDots} from 'src/shared/components/LoadingDots';
import {formatDateForDisplay} from 'src/shared/utils/date';
import {CALLBACK_LOADING, CALLBACK_NOT_SCHEDULED, CALLBACK_SCHEDULED} from '../../constants/callbackStatus';
import {IMMEDIATE_CALLBACK, SCHEDULED_CALLBACK} from '../../constants/appointmentType';
import {talkToAgent} from './propTypes';
import {contactOption, heading, icon, loading, textContainer} from '../shared.module.scss';

const TalkToAgentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" preserveAspectRatio="xMidYMid meet" className={icon} role="img" aria-hidden="true">
    <path fill="#FFA700" d="M3.2 16.65C3 19.85 11.3 28.55 15.5 28.55c0.6 0 1.1-0.2 1.5-0.6l0.6-0.6c0.6-0.6 0.6-1.5 0-2.1L15.3 22.95c-0.6-0.6-1.5-0.6-2.1 0L12 24.45C11.1 23.95 10.2 23.35 9.4 22.55C8.6 21.85 8 20.95 7.6 20.05l1.3-1.3c0.6-0.6 0.6-1.5 0-2.1L6.6 14.35C6 13.85 5.1 13.85 4.5 14.35L3.9 14.95C3.4 15.45 3.2 15.95 3.2 16.65L3.2 16.65" />
    <path fill="#DAE5EC" d="M25.6 3.15h-9.4c-1 0-1.9 0.8-1.9 1.9v13.1l3.3-3.3h9.2c1 0 1.9-0.8 1.9-1.9V4.25c0-0.6-0.5-1.1-1.1-1.1C27.6 3.15 25.6 3.15 25.6 3.15z" />
    <path fill="#A2B9C3" d="M25,6.6h-6.9c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3H25c0.2,0,0.3,0.1,0.3,0.3S25.1,6.6,25,6.6M25,9.4h-6.9c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3H25c0.2,0,0.3,0.1,0.3,0.3C25.3,9.2,25.1,9.4,25,9.4M25,12.1h-6.9c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3H25c0.2,0,0.3,0.1,0.3,0.3S25.1,12.1,25,12.1"></path>
  </svg>
);

export const TalkToAgent = ({
  appointment,
  callbackStatus,
  callbackScheduledContent,
  talkToAgentContent,
  onMobile,
}) => {
  const elem = useRef(null);
  const [impressionFired, setImpressionFired] = useState(false);
  useEffect(() => {
    if (!impressionFired && (callbackStatus === CALLBACK_SCHEDULED || callbackStatus === CALLBACK_NOT_SCHEDULED)) {
      elem.current.dispatchEvent(
        new CustomEvent('c-tracking-log-dom', {
          bubbles: true,
          composed: true,
          detail: {
            eventMethod: 'component-Track',
            component: [{
              componentInfo: {
                componentID: 'contact-option',
                componentName: 'Tile',
                description: 'Tile',
                instanceID: callbackStatus,
              },
            }],
          },
        })
      );
      setImpressionFired(true);
    }
  }, [elem, callbackStatus, setImpressionFired]);
  return (
    <div id="talk-to-agent" className={contactOption} ref={elem} data-tracking={dataTrackingForInstance(callbackStatus)}>
      {
        callbackStatus === CALLBACK_LOADING &&
        <LoadingDots className={loading} />
      }
      {
        callbackStatus === CALLBACK_SCHEDULED &&
        <>
          <TalkToAgentIcon />
          <h2 className={heading}>{callbackScheduledContent.title}</h2>
        </>
      }
      {
        callbackStatus === CALLBACK_SCHEDULED && appointment.type === IMMEDIATE_CALLBACK &&
        <p>{callbackScheduledContent.block}</p>
      }
      {
        callbackStatus === CALLBACK_SCHEDULED && appointment.type === SCHEDULED_CALLBACK &&
        <>
          <p>We&apos;re calling you on {formatDateForDisplay(new Date(appointment.time))}</p>
          <a
            className={button}
            href={callbackScheduledContent.link.url}
            behavior={callbackScheduledContent.link.behavior}
          >
            {callbackScheduledContent.link.text}
          </a>
        </>
      }
      {
        callbackStatus === CALLBACK_NOT_SCHEDULED &&
        <>
          <TalkToAgentIcon className={icon} />
          <div className={textContainer}>
            {!onMobile ? <h2 className={heading}>{talkToAgentContent.title}</h2> :
              <div><b>{talkToAgentContent.title}</b></div>}
            {!onMobile && <p>{talkToAgentContent.block}</p>}
            <a
              className={!onMobile && button}
              href={talkToAgentContent.link.url}
              behavior={talkToAgentContent.link.behavior}
            >
              {talkToAgentContent.link.text}
            </a>
          </div>
        </>
      }
    </div>
  );
};

TalkToAgent.propTypes = talkToAgent;
