import React, {useEffect, useRef} from 'react';
import {dataTrackingForInstance} from 'src/pages/contactUs/components/contactOptionTileDataTracking';
import {button} from 'src/styles/modules/button.module.scss';
import {chat} from './propTypes';
import {XFINITY_ASSISTANT} from 'src/shared/chat/constants';
import {contactOption, heading, icon, textContainer, bodyCopy} from '../shared.module.scss';
import XfinityAssistantIcon from 'src/svgs/guidedsearch/XA.svg';
import {HeadlineTitle} from '../HeadlineTitle';

export const Chat = ({
  currentLanguage,
  launchXfinityAssistant,
  chatWithTechContent,
  chatWithXfinityAssistantContent,
  esChatWithTechContent,
  onMobile,
}) => {
  const elem = useRef(null);
  useEffect(() => {
    elem.current.dispatchEvent(
      new CustomEvent('c-tracking-log-dom', {
        bubbles: true,
        composed: true,
        detail: {
          eventMethod: 'component-Track',
          component: [{
            componentInfo: {
              componentID: 'contact-option',
              componentName: 'Tile',
              description: 'Tile',
              instanceID: XFINITY_ASSISTANT,
            },
          }],
        },
      })
    );
  }, [elem]);
  return (
    <div className={contactOption} data-tracking={dataTrackingForInstance(XFINITY_ASSISTANT)} id="chat" ref={elem}>
      {
        currentLanguage === 'en'
        && (
          <>
            <XfinityAssistantIcon className={icon} />
            <div className={textContainer}>
              <HeadlineTitle title={chatWithXfinityAssistantContent.title} />
              {!onMobile && <div className={bodyCopy}>{chatWithXfinityAssistantContent.block}</div>}
              {!onMobile ?
                <button className={button} onClick={() => launchXfinityAssistant('')}>{chatWithXfinityAssistantContent.link.text}</button>
                : <a href={chatWithXfinityAssistantContent.link.url} behavior={chatWithXfinityAssistantContent.link.behavior}>{chatWithXfinityAssistantContent.link.text}</a>
              }
            </div>
          </>
        )
      }
      {
        currentLanguage === 'es'
        && (
          <>
            <XfinityAssistantIcon className={icon} />
            <h2 className={heading}>{esChatWithTechContent.title}</h2>
            <p>{esChatWithTechContent.block}</p>
            <a
              className={button}
              href={esChatWithTechContent.link.url}
              behavior={chatWithTechContent.link.behavior}
            >
              {esChatWithTechContent.link.text}
            </a>
          </>
        )
      }
    </div>
  );
};

Chat.propTypes = chat;
