import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import {FlexGrid} from 'src/shared/components/FlexGrid';
import {GuidedSearch} from 'src/shared/components/GuidedSearch';
import {LanguageToggle} from 'src/shared/components/LanguageToggle';
import {StatefulHeader} from 'src/shared/components/StatefulHeader';
import {Supportlets} from 'src/shared/components/Supportlets';
import {outageSupportlet} from 'src/shared/components/Supportlets/supportlets/outage';
import {
  reportIssueStandBySupportlet,
  reportIssueSupportlet,
} from 'src/shared/components/Supportlets/supportlets/reportIssue';
import {mainWide} from 'src/styles/modules/main.module.scss';
import {BusinessSupport} from '../BusinessSupport';
import {businessSupport} from '../BusinessSupport/propTypes';
import {Chat} from '../Chat';
import {chat} from '../Chat/propTypes';
import {FindLocation} from '../FindLocation';
import {findLocation} from '../FindLocation/propTypes';
import {HelpCommunities} from '../HelpCommunities';
import {helpCommunities} from '../HelpCommunities/propTypes';
import {LegalContact} from '../LegalContact';
import {legalContact} from '../LegalContact/propTypes';
import {MessageFromTomK} from '../MessageFromTomK';
import {messageFromTomK} from '../MessageFromTomK/propTypes';
import {StatusCenter} from '../StatusCenter';
import {statusCenter} from '../StatusCenter/propTypes';
import {Support24Hrs} from '../Support24Hrs';
import {support24Hrs} from '../Support24Hrs/propTypes';
import {TalkToAgent} from '../TalkToAgent';
import {talkToAgent} from '../TalkToAgent/propTypes';
import {pageSection} from './style.module.scss';
import {Notice} from 'src/shared/components/Notice';
import {XfinityApp} from '../XfinityApp';
import {xfinityApp} from '../XfinityApp/propTypes';
import {ManageBilling} from '../ManageBilling';
import {manageBillingProps} from '../ManageBilling/propTypes';
import {isEmptyOrNull} from '../../../../shared/utils/isEmptyOrNull';
import {TextUs} from '../TextUs';
import {textUsProps} from '../TextUs/propTypes';
import {accessEmailProps} from '../AccessEmail/propTypes';
import {troubleshootInternetProps} from '../TroubleshootInternet/propTypes';
import {TroubleshootInternet} from '../TroubleshootInternet';
import {AccessEmail} from '../AccessEmail';

export const PageContactUs = ({
  appTileEnabled,
  businessSupportContent,
  chatContent,
  findLocationContent,
  futureOutage,
  hasOutageMessagesSegment,
  helpCommunitiesContent,
  launchXfinityAssistant,
  legalContactContent,
  messageFromTomKContent,
  outageImpact,
  outageNotice,
  statusCenterContent,
  support24HrsContent,
  supportTileEnabled,
  talkToAgentContent,
  virtualHoldLinksEnabled,
  xfinityAppContent,
  isStormReadyWifi,
  manageBillingContent,
  accessEmailContent,
  troubleshootInternetContent,
  textUsContent,
  outageList,
  pastOutage,
}) => {
  const onMobile = window.matchMedia('(max-width: 768px)').matches;
  return (

    <>
      <StatefulHeader
        slimNoticeEnabled={true}
        headline="Contact Us"
        tagline="Get in touch with a Comcast specialist. Our team is here to help."
        search={<GuidedSearch placeholder="Search topics or keywords" />}
      />
      <main className={mainWide}>
        {(outageImpact && !futureOutage) && hasOutageMessagesSegment &&
      (
        !isStormReadyWifi || (isStormReadyWifi && !outageList.internetOutage && !pastOutage)
      ) &&
      (
        <section className={pageSection}>
          <Notice {...outageNotice} />
        </section>
      )}
        {
          !hasOutageMessagesSegment && (
            <Supportlets
              className={pageSection}
              supportlets={[
                outageSupportlet,
                reportIssueSupportlet,
                reportIssueStandBySupportlet,
              ]}
            />
          )
        }

        <FlexGrid className={pageSection} columns={3}>
          <Chat launchXfinityAssistant={launchXfinityAssistant} onMobile={onMobile} {...chatContent} />
          {supportTileEnabled && <Support24Hrs onMobile={onMobile} {...support24HrsContent} />}
          {virtualHoldLinksEnabled && <TalkToAgent onMobile={onMobile} {...talkToAgentContent} />}
          {!isEmptyOrNull(manageBillingContent) && <ManageBilling onMobile={onMobile} {...manageBillingContent} />}
          {!isEmptyOrNull(troubleshootInternetContent) && <TroubleshootInternet launchXfinityAssistant={launchXfinityAssistant} onMobile={onMobile} {...troubleshootInternetContent} />}
          {!isEmptyOrNull(accessEmailContent) && <AccessEmail onMobile={onMobile} {...accessEmailContent} />}
          <FindLocation onMobile={onMobile} {...findLocationContent} />
          <StatusCenter onMobile={onMobile} {...statusCenterContent} />
          {!isEmptyOrNull(textUsContent) && <TextUs onMobile={onMobile} {...textUsContent} />}
          {appTileEnabled && <XfinityApp onMobile={onMobile} {...xfinityAppContent} />}
        </FlexGrid>

        <section className={pageSection}>
          <HelpCommunities {...helpCommunitiesContent} />
        </section>

        <section className={pageSection}>
          <MessageFromTomK {...messageFromTomKContent} />
        </section>

        <FlexGrid className={pageSection}>
          <LegalContact {...legalContactContent} />
          <BusinessSupport {...businessSupportContent} />
        </FlexGrid>

      </main>
      <LanguageToggle />
    </>
  );
};

PageContactUs.propTypes = {
  appTileEnabled: PropTypes.bool.isRequired,
  businessSupportContent: PropTypes.shape(businessSupport).isRequired,
  chatContent: PropTypes.shape(omit(chat, 'launchXfinityAssistant')),
  findLocationContent: PropTypes.shape(findLocation),
  futureOutage: PropTypes.bool,
  hasOutageMessagesSegment: PropTypes.bool,
  virtualHoldLinksEnabled: PropTypes.bool,
  helpCommunitiesContent: PropTypes.shape(helpCommunities).isRequired,
  launchXfinityAssistant: chat.launchXfinityAssistant,
  legalContactContent: PropTypes.shape(legalContact).isRequired,
  messageFromTomKContent: PropTypes.shape(messageFromTomK).isRequired,
  outageImpact: PropTypes.string,
  outageNotice: PropTypes.shape({
    action: PropTypes.shape({
      onClick: PropTypes.func,
      text: PropTypes.string,
    }),
    headline: PropTypes.string,
    instanceId: PropTypes.string,
    message: PropTypes.string,
    link: PropTypes.shape({
      target: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
  statusCenterContent: PropTypes.shape(statusCenter),
  support24HrsContent: PropTypes.shape(support24Hrs),
  supportTileEnabled: PropTypes.bool.isRequired,
  talkToAgentContent: PropTypes.shape(talkToAgent),
  xfinityAppContent: PropTypes.shape(xfinityApp),
  isStormReadyWifi: PropTypes.bool,
  manageBillingContent: PropTypes.shape(manageBillingProps),
  accessEmailContent: PropTypes.shape(accessEmailProps),
  troubleshootInternetContent: PropTypes.shape(omit(troubleshootInternetProps, 'launchXfinityAssistant')),
  textUsContent: PropTypes.shape(textUsProps),
  outageList: PropTypes.object,
  pastOutage: PropTypes.bool,
};
